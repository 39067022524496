import Chart from 'react-apexcharts'
import {getCSSVariableValue} from '_metronic/assets/ts/_utils'
import {ApexOptions} from 'apexcharts'
import {FC, Fragment, useEffect, useMemo, useState} from 'react'
import {KTCard, KTCardBody} from '_metronic/helpers'
import {CardText, CardTitle} from 'react-bootstrap'
import Spinner from 'common/components/Spinner'
import {useDispatch} from 'react-redux'
import {AnyAction} from 'redux'
import ErrorAlert from 'common/components/ErrorAlert'
import {DatePicker} from 'common/components/Fields'
import {DurationInputArg1, DurationInputArg2, MomentFormatSpecification} from 'moment'
import moment from 'moment-timezone'
import TimezoneSelect from 'react-timezone-select'

type Filter = {
  id: string
  amount: DurationInputArg1
  unit: DurationInputArg2
  label: string | JSX.Element
}

type Prop = {
  label: string
  tooltip: string
  filters: Filter[]
  responseDateFormat: MomentFormatSpecification
  xAxisDateFormat?: MomentFormatSpecification
  apiFn: ({
    start_date,
    end_date,
    onSuccess,
    onFailure,
  }: {
    start_date: string
    end_date: string
    onSuccess: any
    onFailure: any
  }) => AnyAction
  defaultFilterId: string
}

const getChartOptions = (responseDateFormat: string, xAxisDateFormat: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-light-info')

  return {
    chart: {
      fontFamily: 'inherit',
      toolbar: {
        show: true,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      // type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: (val) => moment(val, responseDateFormat).format(xAxisDateFormat),
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: (val) => val.toLocaleString(),
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

const dateFormat = 'YYYY-MM-DD HH:mm:ss'

const StatsChart: FC<Prop> = ({
  label,
  tooltip,
  filters,
  responseDateFormat,
  xAxisDateFormat,
  defaultFilterId,
  apiFn,
}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [stats, setStats] = useState<{date: string; count: number}[]>([])
  const [dateRange, setDateRange] = useState<string[]>([])
  const [selectedFilter, setSelectedFilter] = useState(defaultFilterId)

  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const handleFilterChange = useMemo(() => {
    return (filterId: string) => {
      setSelectedFilter(filterId)
      if (filterId === `${label}-custom`) return
      const filter = filters.find((filter) => filter.id === filterId)
      if (!filter) return

      const start_date_old = moment(dateRange[0])
      const end_date_old = moment(dateRange[1])

      const start_date_new = moment().subtract(filter.amount, filter.unit)
      const end_date_new = moment()

      if (
        start_date_old.isValid() &&
        end_date_old.isValid() &&
        start_date_old.isSame(start_date_new, 'minute') &&
        end_date_old.isSame(end_date_new, 'minute')
      )
        return

      setDateRange([start_date_new.format(dateFormat), end_date_new.format(dateFormat)])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, label])

  const series = useMemo<ApexAxisChartSeries | ApexNonAxisChartSeries>(() => {
    return [
      {
        name: tooltip,
        data: stats.map((stat: any) => ({
          x: moment(stat.date, responseDateFormat)
            .tz(selectedTimezone)
            .format(responseDateFormat.toString()),
          y: stat.count,
        })),
      },
    ]
  }, [responseDateFormat, selectedTimezone, stats, tooltip])

  useEffect(() => {
    if (dateRange.length === 0) return
    setLoading(true)
    setError(null)

    const onSuccess = (res: any) => {
      setStats(res.result)
      setLoading(false)
    }

    const onFailure = (err: any) => {
      setError(err)
      setStats([] as any)
      setLoading(false)
    }

    dispatch(
      apiFn({
        start_date: moment(dateRange[0]).startOf('day').toISOString(),
        end_date: moment(dateRange[1]).endOf('day').toISOString(),
        onSuccess,
        onFailure,
      })
    )
  }, [apiFn, dateRange, dispatch])

  useEffect(() => {
    handleFilterChange(defaultFilterId)
  }, [defaultFilterId, handleFilterChange])

  return (
    <KTCard>
      <KTCardBody>
        <CardTitle className='row'>
          <div className='col'>
            <CardText>{label}</CardText>
          </div>

          <div className='col'>
            <TimezoneSelect
              id='time-zone-select'
              value={selectedTimezone}
              onChange={(value) => setSelectedTimezone(value.value)}
            />
          </div>

          <div className='col d-flex justify-content-end align-items-center ms-auto'>
            <div className='btn-group' role='group' aria-label='Date filter'>
              {[
                ...filters,
                {
                  id: `${label}-custom`,
                  amount: 1,
                  unit: 'd',
                  label: 'Custom',
                } as Filter,
              ].map((filter) => (
                <Fragment key={filter.id}>
                  <input
                    type='radio'
                    className='btn-check'
                    name={label}
                    id={filter.id}
                    autoComplete='off'
                    checked={filter.id === selectedFilter}
                    onChange={(e) => {
                      if (e.target.checked) {
                        handleFilterChange(filter.id)
                      }
                    }}
                  />
                  <label className='btn btn-outline-primary' htmlFor={filter.id}>
                    {filter.label}
                  </label>
                </Fragment>
              ))}
            </div>
          </div>

          {selectedFilter === `${label}-custom` && (
            <div className='col'>
              <DatePicker
                options={{
                  mode: 'range',
                  maxDate: new Date(),
                  maxTime: new Date(),
                }}
                onChange={(dates) => {
                  if (dates?.length < 2) return
                  setDateRange([
                    moment(dates[0]).format(dateFormat),
                    moment(dates[1]).format(dateFormat),
                  ])
                }}
              />
            </div>
          )}
        </CardTitle>

        {error && <ErrorAlert error={error} />}

        {loading ? (
          <div className='center p-5'>
            <Spinner color='primary' />
          </div>
        ) : (
          <Chart
            options={getChartOptions(
              responseDateFormat.toString(),
              xAxisDateFormat?.toString() || responseDateFormat.toString()
            )}
            series={series}
            type='line'
            height='300'
          />
        )}
      </KTCardBody>
    </KTCard>
  )
}

export default StatsChart
