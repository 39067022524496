import {KTCard, KTCardBody} from '_metronic/helpers'
import Logo from 'common/components/Logo'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {
  calculateAllTenantStats,
  getAllTenants,
  getAllTenantOnlineUserStats,
  getAllTenantStatsProgress,
  getActiveActiveUserStats,
  getInstituteOnlineUserStats,
  getAllTenantStatsCalculatedAt,
  getRequestsStats,
} from 'setup/redux/Actions/School/Stats/stats'
import Platform from './components/Platform'
import BranchWiseOnlineUsers from './components/BranchWiseOnlineUsers'
import Swal from 'sweetalert2'
import {Alert} from 'react-bootstrap'
import StudentParentPlatform from './components/StudentParentPlatform'
import RegisteredUsers from './components/RegisteredUsers'
import OnlineUsers from './components/OnlineUsers'
import {getTenantName} from 'common/HelperFunctions'
import {Select} from 'common/components/Fields'
import Button from 'common/components/Button'
import Switch from 'common/components/Fields/Switch'
import moment from 'moment'
import StatsChart from './components/StatsChart'

let statsProgressInterval: any = null

type OnlineUserStats = {
  branches: number | null
  parentStudentBranchStats: {
    branchId: number
    branchName: string
    totalStudents: number
    totalParents: number
    onlineStudents: number
    onlineParents: number
  }[]
  registeredUsers: {
    user_type: number
    count: number
  }[]
  onlineUsers: {
    total: number
    platforms: {
      web: number
      android: number
      ios: number
    }
    users: {
      students: number
      parents: number
      staff: number
      teachers: number
    }
    platform_users: {
      web: {
        students: number
        parents: number
        staff: number
        teachers: number
      }
      android: {
        students: number
        parents: number
        staff: number
        teachers: number
      }
      ios: {
        students: number
        parents: number
        staff: number
        teachers: number
      }
    }
  }
}

const Stats = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const tenant = getTenantName()

  const [selectedTenant, setSelectedTenant] = useState<string>(tenant)
  const [tenantOptions, setTenantOptions] = useState<{label: string; value: string}[]>([])
  const [allTenantsStatsCalculatedAt, setAllTenantsStatsCalculatedAt] = useState<string | null>(
    null
  )
  const [loadingTenants, setLoadingTenants] = useState(false)

  const [submittingCalculateStats, setSubmittingCalculateStats] = useState(false)
  const [statsProgress, setStatsProgress] = useState('')
  const [statsProgressError, setStatsProgressError] = useState('')

  const [apiKey, setApiKey] = useState('')
  const [loadingOnlineUserStats, setLoadingOnlineUserStats] = useState(true)
  const [onlineUserStats, setOnlineUserStats] = useState<null | OnlineUserStats>(null)
  // const [dailyPlatformSeries, setDailyPlatformSeries] = useState<
  //   ApexAxisChartSeries | ApexNonAxisChartSeries
  // >([])

  const [includeInactiveTenants, setIncludeInactiveTenants] = useState(false)
  const [includeInactiveUsers, setIncludeInactiveUsers] = useState(false)

  const loadOnlineUserStats = useCallback(() => {
    if (!selectedTenant) return
    setLoadingOnlineUserStats(true)

    const onSuccess = (res: any) => {
      setOnlineUserStats(res.result)
      // setDailyPlatformSeries([
      //   {
      //     name: 'Logged In Users',
      //     data: res.result.dailyStats.map((stat: any) => ({
      //       x: stat.date,
      //       y: stat.count,
      //     })),
      //   },
      // ])
      setLoadingOnlineUserStats(false)
    }

    const onFailure = () => {
      toast.error(intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))
      setOnlineUserStats([] as any)
      // setDailyPlatformSeries([
      //   {
      //     name: 'Logged In Users',
      //     data: [],
      //   },
      // ])
      setLoadingOnlineUserStats(false)
    }

    const query = new URLSearchParams()
    if (includeInactiveTenants) {
      query.append('include_inactive_tenants', 'true')
    }
    if (includeInactiveUsers) {
      query.append('include_inactive_users', 'true')
    }

    dispatch(
      getInstituteOnlineUserStats({
        apiKey,
        onSuccess,
        onFailure,
        tenantName: selectedTenant,
        queryString: `?${query.toString()}`,
      })
    )
  }, [apiKey, dispatch, includeInactiveTenants, includeInactiveUsers, intl, selectedTenant])

  const loadAllTenantOnlineUserStats = useCallback(() => {
    setLoadingOnlineUserStats(true)

    const onSuccess = (res: any) => {
      setOnlineUserStats(res.result)
      // setDailyPlatformSeries([
      //   {
      //     name: 'Logged In Users',
      //     data: res.result.dailyStats.map((stat: any) => ({
      //       x: stat.date,
      //       y: stat.count,
      //     })),
      //   },
      // ])
      setLoadingOnlineUserStats(false)
    }

    const onFailure = (err: any) => {
      toast.error(intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))
      setOnlineUserStats([] as any)
      // setDailyPlatformSeries([
      //   {
      //     name: 'Logged In Users',
      //     data: [],
      //   },
      // ])
      setLoadingOnlineUserStats(false)
    }

    const query = new URLSearchParams()
    if (includeInactiveTenants) {
      query.append('include_inactive_tenants', 'true')
    }
    if (includeInactiveUsers) {
      query.append('include_inactive_users', 'true')
    }
    dispatch(
      getAllTenantOnlineUserStats({
        apiKey,
        onSuccess,
        onFailure,
        queryString: `?${query.toString()}`,
      })
    )
  }, [apiKey, dispatch, includeInactiveTenants, includeInactiveUsers, intl])

  const loadTenants = useCallback(() => {
    setLoadingTenants(true)

    const onSuccess = (res: any) => {
      const tenants = res.result.data
      const options = tenants.map((tenant: any) => ({
        label: `${tenant.school_name} (${tenant.app_name})`,
        value: tenant.app_name.split('.')[0],
      }))
      options.push({label: 'LGS (lgs.ilmveristy.net)', value: 'lgs'})
      setTenantOptions([{label: 'All Tenants', value: 'all'}, ...options])
      setLoadingTenants(false)
    }

    const onFailure = () => {
      toast.error(intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))
      setLoadingTenants(false)
    }

    const params: {
      sort?: {
        [key: string]: 'asc' | 'desc'
      }
      search?: {
        [key: string]:
          | {
              value: string | number
              operator:
                | 'eq' // equal
                | 'ne' // not equal
                | 'lt' // less than
                | 'lte' // less than or equal
                | 'gt' // greater than
                | 'gte' // greater than or equal
                | 'like' // like
                | 'notlike'
            }
          | string
          | number
          | string[]
          | number[]
      }
    } = {
      search: {
        application_type: {
          value: 1,
          operator: 'eq',
        },
      },
      sort: {school_name: 'asc'},
    }

    if (!includeInactiveTenants && params.search) {
      params.search.status = {
        value: 1,
        operator: 'eq',
      }
    }

    if (process.env.REACT_APP_ENV === 'local' && params.search?.application_type) {
      delete params.search.application_type
    }

    dispatch(getAllTenants(apiKey, params, onSuccess, onFailure))
  }, [apiKey, dispatch, includeInactiveTenants, intl])

  const loadAllTenantsStatsCalculatedAt = useCallback(() => {
    const onSuccess = (res: any) => {
      setAllTenantsStatsCalculatedAt(res.result.created_at)
    }
    const onFailure = (error: any) => {
      const message = error.response.data.message
      toast.error(message)
    }
    dispatch(getAllTenantStatsCalculatedAt(apiKey, onSuccess, onFailure))
  }, [apiKey, dispatch])

  const handleCalculateStats = () => {
    setSubmittingCalculateStats(true)
    setStatsProgress('')
    setStatsProgressError('')
    const onSuccess = (res: any) => {
      const message = res.result.message
      toast.success(message)

      statsProgressInterval = setInterval(getStatsCalculationProgress, 1000)
    }
    const onFailure = () => {
      toast.error(intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))
      setSubmittingCalculateStats(false)
    }
    dispatch(calculateAllTenantStats(apiKey, onSuccess, onFailure))
  }

  const getStatsCalculationProgress = () => {
    const onSuccess = (res: any) => {
      setStatsProgress(res.result.progress)

      if (res.result.progress === '100%') {
        clearInterval(statsProgressInterval)
        statsProgressInterval = null
        setSubmittingCalculateStats(false)
      }
    }
    const onFailure = (err: any) => {
      setStatsProgressError(err?.message || intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))

      clearInterval(statsProgressInterval)
      statsProgressInterval = null
      setSubmittingCalculateStats(false)
    }

    dispatch(getAllTenantStatsProgress(apiKey, onSuccess, onFailure))
  }

  const HourlyActiveUsers = useMemo(() => {
    if (!apiKey) return null
    return (
      <StatsChart
        label='Hourly Active Users'
        tooltip='Active Users'
        responseDateFormat='YYYY-MM-DD HH:mm:ss'
        xAxisDateFormat='DD-MMM-YYYY hh A'
        filters={[
          {
            id: 'hourly_1_day',
            amount: 1,
            unit: 'd',
            label: '1d',
          },
          {
            id: 'hourly_3_days',
            amount: 3,
            unit: 'd',
            label: '3d',
          },
          {
            id: 'hourly_1_week',
            amount: 1,
            unit: 'w',
            label: '1w',
          },
        ]}
        defaultFilterId='hourly_1_day'
        apiFn={({start_date, end_date, onSuccess, onFailure}) =>
          getActiveActiveUserStats({
            apiKey,
            tenantName: selectedTenant,
            interval: 'hourly',
            start_date,
            end_date,
            onSuccess,
            onFailure,
          })
        }
      />
    )
  }, [apiKey, selectedTenant])

  const DailyActiveUsers = useMemo(() => {
    if (!apiKey) return null
    return (
      <StatsChart
        label='Daily Active Users'
        tooltip='Active Users'
        responseDateFormat='YYYY-MM-DD'
        xAxisDateFormat='DD-MMM-YYYY'
        filters={[
          {
            id: 'daily_1_day',
            amount: 1,
            unit: 'd',
            label: '1d',
          },
          {
            id: 'daily_3_days',
            amount: 3,
            unit: 'd',
            label: '3d',
          },
          {
            id: 'daily_5_days',
            amount: 5,
            unit: 'd',
            label: '5d',
          },
          {
            id: 'daily_1_week',
            amount: 1,
            unit: 'w',
            label: '1w',
          },
        ]}
        defaultFilterId='daily_1_week'
        apiFn={({start_date, end_date, onSuccess, onFailure}) =>
          getActiveActiveUserStats({
            apiKey,
            tenantName: selectedTenant,
            interval: 'daily',
            start_date,
            end_date,
            onSuccess,
            onFailure,
          })
        }
      />
    )
  }, [apiKey, selectedTenant])

  const WeeklyActiveUsers = useMemo(() => {
    if (!apiKey) return null
    return (
      <StatsChart
        label='Weekly Active Users'
        tooltip='Active Users'
        responseDateFormat='DD-MMM-YYYY'
        filters={[
          {
            id: 'weekly_1_week',
            amount: 1,
            unit: 'w',
            label: '1w',
          },
          {
            id: 'weekly_2_weeks',
            amount: 2,
            unit: 'w',
            label: '2w',
          },
          {
            id: 'weekly_3_weeks',
            amount: 3,
            unit: 'w',
            label: '3w',
          },
          {
            id: 'weekly_4_weeks',
            amount: 4,
            unit: 'w',
            label: '4w',
          },
        ]}
        defaultFilterId='weekly_2_weeks'
        apiFn={({start_date, end_date, onSuccess, onFailure}) =>
          getActiveActiveUserStats({
            apiKey,
            tenantName: selectedTenant,
            interval: 'weekly',
            start_date,
            end_date,
            onSuccess,
            onFailure,
          })
        }
      />
    )
  }, [apiKey, selectedTenant])

  const MonthlyActiveUsers = useMemo(() => {
    if (!apiKey) return null
    return (
      <StatsChart
        label='Monthly Active Users'
        tooltip='Active Users'
        responseDateFormat='MMM-YYYY'
        filters={[
          {
            id: 'monthly_2_months',
            amount: 2,
            unit: 'months',
            label: '2m',
          },
          {
            id: 'monthly_6_months',
            amount: 6,
            unit: 'months',
            label: '6m',
          },
          {
            id: 'monthly_1_year',
            amount: 1,
            unit: 'year',
            label: '1y',
          },
        ]}
        defaultFilterId='monthly_2_months'
        apiFn={({start_date, end_date, onSuccess, onFailure}) =>
          getActiveActiveUserStats({
            apiKey,
            tenantName: selectedTenant,
            interval: 'monthly',
            start_date,
            end_date,
            onSuccess,
            onFailure,
          })
        }
      />
    )
  }, [apiKey, selectedTenant])

  const DailyRequests = useMemo(() => {
    if (!apiKey) return null
    return (
      <StatsChart
        label='Daily Requests'
        tooltip='Requests'
        responseDateFormat='YYYY-MM-DD'
        xAxisDateFormat='DD-MMM-YYYY'
        filters={[
          {
            id: 'daily_requests_1_day',
            amount: 1,
            unit: 'd',
            label: '1d',
          },
          {
            id: 'daily_requests_3_days',
            amount: 3,
            unit: 'd',
            label: '3d',
          },
          {
            id: 'daily_requests_5_days',
            amount: 5,
            unit: 'd',
            label: '5d',
          },
          {
            id: 'daily_requests_1_week',
            amount: 1,
            unit: 'w',
            label: '1w',
          },
        ]}
        defaultFilterId='daily_requests_1_week'
        apiFn={({start_date, end_date, onSuccess, onFailure}) =>
          getRequestsStats({
            apiKey,
            tenantName: selectedTenant,
            interval: 'daily',
            start_date,
            end_date,
            onSuccess,
            onFailure,
          })
        }
      />
    )
  }, [apiKey, selectedTenant])

  const MonthlyRequests = useMemo(() => {
    if (!apiKey) return null
    return (
      <StatsChart
        label='Monthly Requests'
        tooltip='Requests'
        responseDateFormat='MMM-YYYY'
        filters={[
          {
            id: 'monthly_requests_2_months',
            amount: 2,
            unit: 'months',
            label: '2m',
          },
          {
            id: 'monthly_requests_6_months',
            amount: 6,
            unit: 'months',
            label: '6m',
          },
          {
            id: 'monthly_requests_1_year',
            amount: 1,
            unit: 'year',
            label: '1y',
          },
        ]}
        defaultFilterId='monthly_requests_2_months'
        apiFn={({start_date, end_date, onSuccess, onFailure}) =>
          getRequestsStats({
            apiKey,
            tenantName: selectedTenant,
            interval: 'monthly',
            start_date,
            end_date,
            onSuccess,
            onFailure,
          })
        }
      />
    )
  }, [apiKey, selectedTenant])

  useEffect(() => {
    Swal.fire({
      title: 'Enter API Key',
      input: 'text',
      inputPlaceholder: 'Your API Key',
      showCancelButton: false,
      confirmButtonText: 'Submit',
      allowOutsideClick: false,
      preConfirm: (key) => {
        if (!key) {
          Swal.showValidationMessage('API Key is required')
        }
        return key
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setApiKey(result.value)
      }
    })
  }, [])

  useEffect(() => {
    if (apiKey) {
      if (tenant === 'demo') {
        loadTenants()
        loadAllTenantsStatsCalculatedAt()
      }

      if (selectedTenant === 'all') {
        loadAllTenantOnlineUserStats()
      } else {
        loadOnlineUserStats()
      }
    }
  }, [
    apiKey,
    loadAllTenantOnlineUserStats,
    loadAllTenantsStatsCalculatedAt,
    loadOnlineUserStats,
    loadTenants,
    selectedTenant,
    tenant,
  ])

  if (!apiKey) {
    return (
      <div style={{height: '90vh'}}>
        <KTCard className='h-100'>
          <KTCardBody>
            <Alert variant='danger'>
              <Alert.Heading>API Key is required</Alert.Heading>
              <p>Please enter your API Key to continue</p>
            </Alert>
          </KTCardBody>
        </KTCard>
      </div>
    )
  }

  return (
    <>
      {selectedTenant !== 'all' && (
        <div className='center mb-5'>
          <Logo showBranchLogo={false} />
        </div>
      )}

      {tenant === 'demo' && (
        <>
          <KTCard className='mb-3'>
            <KTCardBody className='row'>
              <div className='col-md-10 row'>
                <div className='col-md-6'>
                  <Select
                    className='mb-3'
                    label='Tenant'
                    options={tenantOptions}
                    value={selectedTenant}
                    onChange={(value) => setSelectedTenant(value)}
                    isLoading={loadingTenants}
                  />
                </div>

                <div className='col-md-6 row'>
                  {selectedTenant === 'all' && (
                    <div className='col-md-6'>
                      <Switch
                        id='includeInactiveTenants'
                        className='mt-3'
                        labelClassName='ms-2'
                        label='include all active users'
                        orientation='right'
                        onChange={(v) => setIncludeInactiveTenants(v)}
                        disabled={loadingOnlineUserStats}
                      />
                    </div>
                  )}

                  <div className='col-md-6'>
                    <Switch
                      id='includeInactiveUsers'
                      className='mt-3'
                      labelClassName='ms-2'
                      label='include all inactive users'
                      orientation='right'
                      onChange={(v) => setIncludeInactiveUsers(v)}
                      disabled={loadingOnlineUserStats}
                    />
                  </div>
                </div>
              </div>

              <div className='col-md-2 text-end'>
                {statsProgressError && (
                  <Alert variant='danger' className='text-start'>
                    {statsProgressError}
                  </Alert>
                )}

                <Button
                  onClick={handleCalculateStats}
                  loading={submittingCalculateStats}
                  disabled={submittingCalculateStats}
                >
                  <i className='fa fa-refresh' />
                  Refresh Stats
                </Button>
                {statsProgress && !statsProgressError && (
                  <span className='badge badge-light-info ms-3'>{statsProgress}</span>
                )}

                {allTenantsStatsCalculatedAt && (
                  <>
                    <br />
                    Last Calculated At:
                    <span className='fw-bold ms-1'>
                      {moment(allTenantsStatsCalculatedAt).format('LLL')}
                    </span>
                  </>
                )}
              </div>
            </KTCardBody>
          </KTCard>

          {selectedTenant === 'all' && (
            <div className='row mb-3'>
              <div className='col-md-6'>
                <div className='card card-flush h-100 bg-light-success'>
                  <div className='card-header'>
                    <div className='card-title d-flex w-100 justify-content-between'>
                      <span className='pt-1 fw-bold fs-6 text-success'>Total Tenants</span>

                      <div className='d-flex align-items-center'>
                        <span className='fs-2hx fw-bolder me-2 lh-1 ls-n2'>
                          {(tenantOptions.length - 1).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!loadingOnlineUserStats && (
                <div className='col-md-6'>
                  <div className='card card-flush h-100 bg-light-success'>
                    <div className='card-header'>
                      <div className='card-title d-flex w-100 justify-content-between'>
                        <span className='pt-1 fw-bold fs-6 text-success'>Total Schools</span>

                        <div className='d-flex align-items-center'>
                          <span className='fs-2hx fw-bolder me-2 lh-1 ls-n2'>
                            {(onlineUserStats?.branches || 0).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}

      <div className='row gy-5'>
        <div className='col-md-6'>
          <RegisteredUsers
            stats={onlineUserStats?.registeredUsers}
            loading={loadingOnlineUserStats}
          />
        </div>

        <div className='col-md-6'>
          <OnlineUsers
            stats={onlineUserStats?.onlineUsers.users}
            total={onlineUserStats?.onlineUsers.total}
            loading={loadingOnlineUserStats}
          />
        </div>

        <div className={selectedTenant !== 'all' ? 'col-md-6' : 'col-12'}>
          {onlineUserStats?.onlineUsers.platforms && (
            <Platform
              total={onlineUserStats.onlineUsers.total}
              stats={onlineUserStats.onlineUsers.platforms}
              className='h-100'
              loading={loadingOnlineUserStats}
            />
          )}
        </div>

        {selectedTenant !== 'all' && (
          <div className='col-md-6'>
            <BranchWiseOnlineUsers
              stats={onlineUserStats?.parentStudentBranchStats}
              className='h-100'
              loading={loadingOnlineUserStats}
            />
          </div>
        )}

        <div className='col-12'>
          <StudentParentPlatform
            stats={onlineUserStats?.onlineUsers.platform_users}
            userStats={onlineUserStats?.onlineUsers.users}
            loading={loadingOnlineUserStats}
          />
        </div>

        {/* <div className='col-12'>
          <DailyLogins
            dailyPlatformSeries={dailyPlatformSeries}
            className='h-100'
            loading={loadingOnlineUserStats}
          />
        </div> */}

        <div className='col-md-6'>{HourlyActiveUsers}</div>

        <div className='col-md-6'>{DailyActiveUsers}</div>

        <div className='col-md-6'>{WeeklyActiveUsers}</div>

        <div className='col-md-6'>{MonthlyActiveUsers}</div>

        <div className='col-md-6'>{DailyRequests}</div>

        <div className='col-md-6'>{MonthlyRequests}</div>
      </div>
    </>
  )
}

export default Stats
